import { all, call, put } from 'redux-saga/effects';
import { resourceWatchers, takeMap } from 'lib/saga-utils';
import { api } from 'lib/api';
import { issues, issuesSaga } from 'app/issues';
import { reportsSaga } from 'app/reports';
import { checkinsSaga } from 'app/checkins';
import { events, eventsSaga } from 'app/events';
import { locations, locationsSaga } from 'app/locations';
import { usersSaga, employeesSaga } from 'app/users';
import { issueTypesSaga } from 'app/issueTypes';
import { metaflowsSaga } from 'app/metaflows';
import { substitutionsSaga } from 'app/substitutions';
import { companiesSaga } from 'app/companies';
import { activitiesSaga } from 'app/activities';
import { locationTypes } from 'app/locationTypes';
import { notificationsSaga } from 'app/notifications';
import { managedObjectsSaga } from 'app/managedObjects';
import bookingsSaga from 'app/bookings/bookings.saga';
import { meters } from 'app/meters';
import dicts from 'app/dicts.resource';
import attachmentsResource from 'app/attachments.resource';
import { issueSchedulesSaga } from 'app/issueSchedules';
import inventories from 'app/inventories/inventories.resource';
import attributes from 'app/attributes/attributes.resource';
import inventoriesSaga from 'app/inventories/inventories.saga';
import userRoles from 'app/userRoles/userRoles.resource';
// import analyticsSaga from 'services/analytics/analytics.saga';
import announcements from 'app/announcements/announcements.resource';
import { router } from 'config/router';
import { openModal } from 'lib/ui/Modal';

import { dashboardsResource } from './analyticalReports';
import { smsSaga } from './sms';
import { bookingItemTypesSaga } from './bookingItemTypes';
import { schedulesSaga } from './schedules';
import { roundReportsSaga } from './roundsReports';
import { fieldsResources } from './fieldsComparison';

function* filesSaga() {
  yield all([
    call(
      resourceWatchers({
        GET_FILES({ fileIds }) {
          return api.get('/files/', {
            search: {
              ids: fileIds.join(','),
            },
          });
        },
      }),
    ),
    call(
      takeMap({
        NAVIGATE({ payload, withReload }) {
          if (withReload) {
            router.navigate(payload, { replace: true });
          } else {
            router.navigate(payload);
          }
        },
      }),
    ),
  ]);
}

function* modalsSaga() {
  yield call(
    takeMap({
      *CLOSE_MODAL({ modalToOpenAfterClose }) {
        if (modalToOpenAfterClose) {
          yield put(
            openModal(
              modalToOpenAfterClose.name,
              modalToOpenAfterClose.data,
              modalToOpenAfterClose.extraData,
            ),
          );
        }
      },
    }),
  );
}

export default function* () {
  yield all([
    call(notificationsSaga),
    call(modalsSaga),
    call(issuesSaga),
    call(issues.saga),
    call(reportsSaga),
    call(checkinsSaga),
    call(usersSaga),
    call(filesSaga),
    call(locationsSaga),
    call(locations.saga),
    call(issueTypesSaga),
    call(metaflowsSaga),
    call(substitutionsSaga),
    call(companiesSaga),
    call(activitiesSaga),
    call(locationTypes.saga),
    call(dicts.saga),
    call(events.saga),
    call(eventsSaga),
    call(meters.saga),
    call(attachmentsResource.saga),
    call(issueSchedulesSaga),
    // call(analyticsSaga),
    call(inventories.saga),
    call(attributes.saga),
    call(inventoriesSaga),
    call(userRoles.saga),
    call(announcements.saga),
    call(managedObjectsSaga),
    call(bookingsSaga),
    call(bookingItemTypesSaga),
    call(schedulesSaga),
    call(smsSaga),
    call(roundReportsSaga),
    call(employeesSaga),
    call(dashboardsResource.saga),
    call(fieldsResources.saga),
  ]);
}
