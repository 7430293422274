import { memo, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import './UniversalLink.scss';

interface Props {
  to:
    | string
    | {
        pathname: string;
        query: Record<string, any>;
      };
  external?: boolean;
  children: ReactNode;
  className?: string;
}

const { block } = bem('UniversalLink');

const UniversalLink = ({ to, external, className, children }: Props) => {
  if (external) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" {...block({}, className)}>
        {children}
      </a>
    );
  }
  return (
    <Link to={to} {...block({}, className)}>
      {children}
    </Link>
  );
};

export default memo(UniversalLink);
