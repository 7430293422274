import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient_v4 } from 'shared/api/v4/client';
import { UpdateActivityDataSchema } from 'shared/api/v4/swagger/data-contracts';

import { activitiesQueries } from './queries';

export const useDeleteActivity = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (activityId: number) => {
      await apiClient_v4.activities.deleteActivity({ activityId });
      return { activityId };
    },

    onSuccess: () => queryClient.invalidateQueries({ queryKey: activitiesQueries._def }),
  });
};

export const useUpdateActivity = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ activityId, item }: { activityId: number; item: UpdateActivityDataSchema }) => {
      return apiClient_v4.activities.updateActivity({ activityId, item });
    },

    onSuccess: () => queryClient.invalidateQueries({ queryKey: activitiesQueries._def }),
  });
};
export const useCreateActivity = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ item }: { item: UpdateActivityDataSchema }) => {
      return apiClient_v4.activities.createActivity({ item });
    },

    onSuccess: () => queryClient.invalidateQueries({ queryKey: activitiesQueries._def }),
  });
};
