import { Helmet } from 'react-helmet';

import { BrandName } from 'types/brand';
import { isPwa } from 'lib/helpers/pwa';
import { brandName } from 'config/constants';

const brandedData: Record<BrandName, any> = {
  officeservice: {
    apple: {
      appId: 1492901162,
      appClipId: 'office.service',
    },
    google: {
      appId: 'office.service',
    },
  },
  tagpoint: {
    apple: {
      appId: 1581587003,
      appClipId: 'tag.point',
    },
    google: {
      appId: 'tag.point',
    },
  },
  teorema: {
    apple: {
      appId: 1021199196,
      appClipId: 'ru.sbcs',
    },
    google: {
      appId: 'ru.sbcs',
    },
  },
  waveservice: {
    apple: {
      appId: 1139141510,
      appClipId: 'space.smart',
    },
    google: {
      appId: 'space.smart',
    },
  },
  waveserviceEN: {
    apple: {
      appId: 1139141510,
      appClipId: 'space.smart',
    },
    google: {
      appId: 'space.smart',
    },
  },
  landia: {
    apple: {
      appId: 1615389766,
      appClipId: 'com.g.landia',
    },
    google: {
      appId: 'g.landia.eco',
    },
  },
  zabotaapp: {
    apple: {
      appId: 6449288858,
      appClipId: 'space.smart',
    },
    google: {
      appId: 'zabota.app',
    },
  },
  suop: {
    apple: {
      appId: 6449288858,
      appClipId: 'space.smart',
    },
    google: {
      appId: 'zabota.app',
    },
  },
  gsp: {
    apple: {
      appId: 6466608312,
      appClipId: 'gsp.service',
    },
    google: {
      appId: 'gsp.service',
    },
  },
  'code-service': {
    apple: {
      appId: 6480408957,
      appClipId: 'space.smart',
    },
    google: {
      appId: 'space.smart',
    },
  },
  nornikel: {
    apple: {
      appId: 6504757959,
      appClipId: 'space.smart',
    },
    google: {
      appId: 'space.smart',
    },
  },
  elma: {
    apple: {
      appId: 6587573250,
      appClipId: 'space.smart',
    },
    google: {
      appId: 'elma.service',
    },
  },
  rkvr: {
    apple: {
      appId: 6642703495,
      appClipId: 'space.smart',
    },
    google: {
      appId: 'rukver.service',
    },
  },
};

interface Props {
  withAppClips?: boolean;
}

const PhoneStoreLinks = ({ withAppClips }: Props) => {
  const brandData = brandedData[brandName];
  const appleItunesAppContent = `app-id=${brandData.apple.appId}${
    withAppClips ? `, app-clip-bundle-id=${brandData.apple.appClipId}.Clip` : ''
  }`;

  if (isPwa()) {
    return null;
  }

  return (
    <Helmet>
      <meta name="apple-itunes-app" content={appleItunesAppContent} />
      ,
      <meta name="google-play-app" content={`app-id=${brandData.google.appId}`} />
    </Helmet>
  );
};

export default PhoneStoreLinks;
