import { range } from 'lodash';
import moment from 'moment';
import { BookingStatus, DayInfo } from '../types';
import { DATE_WITHOUT_TIME } from '../../../../../constants/dates';

const generateSlot = (year: number, month: number, dayOfMonth: number) => {
  const date = moment().set({ year, month, date: dayOfMonth });
  return {
    date: date.format(DATE_WITHOUT_TIME),
    slots: [],
    status: BookingStatus.EMPTY_SLOTS,
  };
};

export const addMissingSlotsToEachMonth = (slotsByMonth: Record<string, DayInfo[]>) => {
  const result = { ...slotsByMonth };
  Object.keys(result).forEach((month) => {
    const parsedMonth = moment(month, 'YYYY-MM');
    const monthDaysNumber = parsedMonth.daysInMonth();
    const yearNumber = parsedMonth.year();
    const monthNumber = parsedMonth.month();

    if (result[month].length !== monthDaysNumber) {
      const firstSlot = result[month][0];
      const endSlot = result[month][result[month].length - 1];

      const firstSlotDayOfMonth = moment(firstSlot.date, DATE_WITHOUT_TIME).date();
      const endSlotDayOfMonth = moment(endSlot.date, DATE_WITHOUT_TIME).date();

      if (firstSlotDayOfMonth === 1) {
        let currentDayOfMonth = endSlotDayOfMonth + 1;
        while (currentDayOfMonth <= monthDaysNumber) {
          result[month].push(generateSlot(yearNumber, monthNumber, currentDayOfMonth));
          currentDayOfMonth++;
        }
      } else {
        let currentDayOfMonth = 1;
        const slotsBefore: DayInfo[] = [];
        while (currentDayOfMonth !== firstSlotDayOfMonth) {
          slotsBefore.push(generateSlot(yearNumber, monthNumber, currentDayOfMonth));
          currentDayOfMonth++;
        }
        result[month] = slotsBefore.concat(result[month]);
      }
    }
  });

  const emptySlot: DayInfo = { date: '', slots: [], status: BookingStatus.UNAVAILABLE };
  Object.keys(result).forEach((month) => {
    const monthSlots = result[month];
    const firstMonthSlot = monthSlots[0];
    const convertedDate = moment(firstMonthSlot.date);
    result[month] = range(moment.locale() === 'en' ? 0 : 1, convertedDate.day()).map(() => emptySlot).concat(result[month]);
  });

  return result;
};
