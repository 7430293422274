import { Mutation } from '@tanstack/react-query';
import { contextNotification } from 'app/notifications';
import { smsSettingsMutationKeys } from 'entities/sms-settings/api/mutations';
import { NotificationsObserver } from 'features/notify';

export const notifyMutationSuccess = (mutation: Mutation<unknown, unknown, unknown, unknown>) => {
  const { mutationKey } = mutation.options;
  switch (mutationKey) {
    case smsSettingsMutationKeys.update:
      NotificationsObserver.addNotification(contextNotification('sms')('settings_updated').message);
      break;
    default:
      break;
  }
};
