/* eslint-disable camelcase */
import { forwardRef } from 'react';

import { bem } from 'lib/bem';
import { UrlIcon } from 'lib/ui/icon';
import { DndItemAction } from 'shared/ui/dnd-provider';

import './ContentBlock.scss';

type ContentBlockProps = {
  name: string;
  icon_url?: string;
  subtitle?: string;
  editAction?: DndItemAction;
};

const { element } = bem('ContentBlock');

export const ContentBlock = forwardRef<HTMLDivElement, ContentBlockProps>(
  function ContentBlockComponent({ icon_url, name, subtitle, editAction }, ref) {
    return (
      <div ref={ref}>
        <div className={element('wrapper').className}>
          {icon_url && <UrlIcon url={icon_url} className={element('icon').className} />}
          <div className={element('title').className} onClick={editAction?.onClick}>
            <h1 className={element('title--primary').className}>{name}</h1>
            <p className={element('title--secondary').className}>{subtitle}</p>
          </div>
        </div>
      </div>
    );
  },
);
