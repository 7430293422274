import { api } from '../api-methods';
import { getSaveIssueChanges } from './api-routes';

class IssuesApi {
  saveIssueChanges(payload: { id: number; attributes: Record<string, unknown> }) {
    const { id, attributes } = payload;
    return api.post(getSaveIssueChanges(id), {
      body: {
        attributes,
      },
    });
  }
}

export const apiClient_v1 = {
  issues: new IssuesApi(),
};
