import { all, call } from 'redux-saga/effects';
import { takeMap } from 'lib/saga-utils';
import { events } from 'app/events';

export default function* eventsSaga() {
  yield all([
    call(takeMap({
      'METERS/SAVEMETRICS_SUCCESS': function ({ response }) {
        (events as any).list.invalidate();
      },
    })),
  ]);
}
