import { ManagedObjectTreeNode } from 'shared/api/v4/swagger/data-contracts';
// eslint-disable-next-line import/no-cycle
import { TreeData, TreeNode } from 'shared/ui/async-tree-view';
import { LocationApiSchema } from 'shared/api/v2/data-contracts';
import { t } from 'lib/i18n';

import { bfsSearch } from './bfs-search';

export const ROOT_NODE_ID = 0;
export const SUGGESTED_NODE_ID = -1;

export const getAllIds = (
  treeData: TreeData,
  node: TreeNode,
  idList: string[] = [],
): null | string[] => {
  if (!node) return null;

  idList.push(String(node.id));
  if (node.hasChildren && treeData[node.id]) {
    treeData[node.id].children.forEach((child) => getAllIds(treeData, child, idList));
  }
  return idList;
};

export const getGrandParents = (
  treeData: TreeData,
  parentId: number | undefined,
  list: string[] = [],
): string[] => {
  if (parentId == null) return [];

  list.push(parentId.toString());

  const grandParentId = treeData[parentId]?.parentId;

  if (grandParentId != null) {
    return getGrandParents(treeData, grandParentId, list);
  }

  return list;
};

export interface Node {
  id: number;
  name: string;
  parentId?: number;
  parentIds?: string[];
}

export const extractFlatTree = (treeData: TreeData) => {
  const flatData: Map<number, Node> = new Map<number, Node>();

  const expandNodes = (treeNodes: TreeNode[]) => {
    treeNodes.forEach((node) => {
      flatData.set(node.id, {
        id: node.id,
        name: node.name,
        parentId: node.parentId,
      });
    });
  };

  Object.keys(treeData).forEach((key) => expandNodes(treeData[key].children));
  return flatData;
};

export const expandNode = (item: ManagedObjectTreeNode, parentIds?: string[]): TreeNode => {
  // Костя гарантировал, что id не может быть undefined и всегда есть числовое значение, но swagger сгенерил number|undefined

  return {
    id: item.id!,
    name: item.name,
    children: item.children,
    hasChildren: item.has_children,
    parentId: item.parent_id,
    data: item,
    parentIds,
  };
};

export const extractTree = (tree: ManagedObjectTreeNode[]): TreeData => {
  const treeData: TreeData = {
    [String(ROOT_NODE_ID)]: { children: [] },
  };

  const expandNodes = (
    treeObject: ManagedObjectTreeNode[],
    id: string | number,
    parentId?: number,
  ) => {
    const parents = [String(id), ...getGrandParents(treeData, parentId)];

    treeData[id] = {
      parentId,
      children: treeObject.map((item) => expandNode(item, parents)),
    };

    treeObject.forEach((node) => {
      if (node.id && node.has_children && node.children && node.children.length > 0) {
        expandNodes(node.children, node.id, Number(id));
      }
    });
  };

  expandNodes(tree, String(ROOT_NODE_ID));
  return treeData;
};

export const getAllChildren = (treeData: TreeData, id: number) => {
  const node = bfsSearch(treeData, id);
  return node ? getAllIds(treeData, node) ?? [] : [];
};

export function isAllChildrenChecked(
  treeData: TreeData,
  node: TreeNode,
  list: string[],
  selectedNodeIds: string[],
) {
  const allChild = getAllChildren(treeData, node.id);
  if (!allChild) return true;

  const nodeIdIndex = allChild.indexOf(String(node.id));
  allChild.splice(nodeIdIndex, 1);

  const selectedNodesArray = selectedNodeIds.concat(list);

  return allChild.every((nodeId) => selectedNodesArray.includes(String(nodeId)));
}

export const convertLocationsArrayIntoTreeNode = (locations: LocationApiSchema[]): TreeNode => {
  const children: TreeNode[] = locations.map((i) => ({
    id: i.id,
    name: i.full_path,
    hasChildren: false,
    parentId: SUGGESTED_NODE_ID,
    parentIds: [String(ROOT_NODE_ID), String(SUGGESTED_NODE_ID)],
    data: {
      ...i,
      managed_object_type_name: i.managed_object_type.name,
    },
  }));

  return {
    id: SUGGESTED_NODE_ID,
    name: t('locations.fields.suggested'),
    hasChildren: true,
    parentId: ROOT_NODE_ID,
    children,
    parentIds: [String(ROOT_NODE_ID)],
  };
};
