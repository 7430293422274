import { number, object, ObjectSchema, string } from 'yup';
import { UpdateActivityDataSchema } from 'shared/api/v4/swagger/data-contracts';

export const activitySettingsUpdateSchema: ObjectSchema<UpdateActivityDataSchema> = object({
  name: string().required(),
  icon_url: string().required(),
  type: string().required(),
  params: object(),
  before_id: number(),
});
