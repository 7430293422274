import { UpdateActivityDataSchema } from 'shared/api/v4/swagger/data-contracts';

import { ActivitySettingsFormData } from './types';

export const mapActivitySettingsToForm = ({
  name = '',
  icon_url = '',
  type = '',
  params = {},
  before_id = 0,
}: UpdateActivityDataSchema): ActivitySettingsFormData => ({
  name,
  icon_url,
  type,
  params,
  before_id,
});
