import { api } from '../api-methods';
import { getUrlDeleteUser } from './api-routes';
import { DeleteProfileResponseSchema } from './data-contracts';

class UserApi {
  // Remove a location
  deleteUser() {
    return api.post<DeleteProfileResponseSchema>(getUrlDeleteUser());
  }
}

export const apiClient_v3 = {
  user: new UserApi(),
};
