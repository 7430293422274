import { ReactNode, useCallback, useEffect } from 'react';
import {
  FieldErrors,
  FieldValues,
  FormProvider,
  FormState,
  Resolver,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

type FormProps<FormData extends FieldValues> = {
  initValues: FormData;
  onSubmitFailed?: SubmitErrorHandler<FormData>;
  resolver: Resolver<FormData>;
  onSubmit: SubmitHandler<FormData>;
  onFormStateChange?: (formState: FormState<FormData>) => void;
  children: ReactNode;
};

// Указываем в дженерике тип, который получается после заполнения и валидации формы
export const Form = <FormData extends FieldValues>({
  initValues,
  children,
  resolver,
  onSubmit,
  onSubmitFailed,
  onFormStateChange,
}: FormProps<FormData>) => {
  const methods = useForm<FormData>({
    mode: 'onSubmit',
    resolver,
    values: initValues,
  });

  const { formState } = methods;

  useEffect(() => {
    onFormStateChange?.(formState);
  }, [formState, onFormStateChange]);

  const handleSubmit = useCallback(
    (formData: FormData) => {
      return onSubmit(formData);
    },
    [onSubmit],
  );

  const handleSubmitError = useCallback(
    (errors: FieldErrors<FormData>) => {
      return onSubmitFailed?.(errors);
    },
    [onSubmitFailed],
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit, handleSubmitError)}>{children}</form>
    </FormProvider>
  );
};
