import './LocationTreeSelect.scss';
import { PureComponent } from 'react';
import { flowRight, get, keyBy, debounce } from 'lodash';
import { formValues } from 'redux-form';

import { reconnect } from 'lib/resource';
import { t } from 'lib/i18n';
import { bem } from 'lib/bem';
import { Checkbox, FormBuilder } from 'lib/ui';
import { formatTreeValue, normalizeTreeValue } from 'lib/queryForm';
import { locations as locationsResource } from 'app/locations';
import users from 'app/users/users.resource';
import { getFullPath, getName } from 'shared/lib/get-location-name';

import brandedData from '../../../config/brandedData';

const { block } = bem('LocationTreeSelect');

type LocationTreeSelectContainerState = any;

export default class LocationTreeSelectContainer extends PureComponent<
  any,
  LocationTreeSelectContainerState
> {
  state = {
    search: '',
    onlyMyLocations: false,
  };

  render() {
    const { forceOnlyManagerLocations } = this.props;
    const { onlyMyLocations, search } = this.state;

    return (
      <LocationTreeSelect
        onlyMyLocations={forceOnlyManagerLocations || onlyMyLocations}
        {...this.props}
        search={search}
        onSearch={this.handleSearch}
        onOnlyMyChange={this.handleFilterChange}
      />
    );
  }

  handleSearch = debounce((search) => {
    this.setState({ search });
  }, 300);

  handleFilterChange = (value) => {
    this.setState((state) => ({
      onlyMyLocations: value,
    }));
  };
}

const LocationTreeSelect = flowRight<any, any, any, any>(
  formValues((props) => ({ values: props.name })),
  reconnect((state, props) => ({
    currentUser: (users as any).current(state, {}),
    locationValues: (locationsResource as any).list(
      state,
      props.forQueryForm && props.values ? { ids: props.values } : undefined,
    ),
  })),
  reconnect((state, ownProps) => {
    const search = () => {
      if (ownProps.verifiable) {
        return locationsResource.verifiable(
          state,
          {
            mcid: ownProps.currentUser.data.management_company_id,
          },
          { skipFetchState: true },
        );
      }

      return locationsResource.tree(
        state,
        {
          search: ownProps.search,
          manager: ownProps.onlyMyLocations ? 'true' : 'f',
        },
        { skipFetchState: true },
      );
    };

    if (ownProps.bc) {
      return {
        locations:
          ownProps.verifiable ||
          ownProps.search ||
          ownProps.onlyMyLocations ||
          ownProps.loadFullTree
            ? search()
            : locationsResource.bcOnly(state, { without_children: 't' }),
      };
    }

    return {
      locations:
        ownProps.verifiable || ownProps.search || ownProps.onlyMyLocations || ownProps.loadFullTree
          ? search()
          : locationsResource.newtree(state, {}),
    };
  }),
)(
  class LocationTreeSelect extends PureComponent<any> {
    static defaultProps = {
      modifyTree: (i) => i,
      modifyValues: (i) => i,
    };

    private fieldKey = 0;

    private myLocations: any;

    UNSAFE_componentWillReceiveProps(nextProps) {
      this.createMyLocationsIndex(nextProps.currentUser.data.managed_locations);

      if (nextProps.locations.data !== this.props.locations.data) {
        this.fieldKey++;
      }
    }

    UNSAFE_componentWillMount() {
      this.fieldKey = 1;
    }

    createMyLocationsIndex(locations) {
      this.myLocations = keyBy(locations || [], (i) => i);
    }

    componentDidMount() {
      this.createMyLocationsIndex(this.props.currentUser.data.managed_locations);

      if (this.props.forceOnlyManagerLocations) {
        this.setState({
          onlyMyLocations: true,
        });
      }
    }

    get locationValues() {
      const { data } = this.props.locationValues;

      if (!data) return null;

      return {
        byId: keyBy(this.props.modifyValues(data), 'id'),
      };
    }

    render() {
      const { multiple, forQueryForm, onlyMyLocations, verifiable, withLocationCards } = this.props;
      const myLocations = this.getMyLocations();

      return (
        <FormBuilder.TreeViewSelect
          startIcon="pin"
          withLocationCards={withLocationCards}
          title={t('locations.title')}
          tree={this.getLocationsWithSuggested()}
          getLabel={getName}
          normalize={forQueryForm ? normalizeTreeValue(multiple) : undefined}
          format={forQueryForm ? formatTreeValue(multiple, this.locationValues) : undefined}
          renderFilter={this.renderFilter}
          getItemFullPath={getFullPath}
          // onSearch={this.props.onSearch}
          filterable={verifiable || false}
          expandedLevel={onlyMyLocations || this.isSearched() ? 1000 : -1}
          {...this.props}
          {...block({
            withMy: myLocations && myLocations.length,
            onlyMy: this.showOnlyMyLocations(),
          })}
        />
      );
    }

    getMyLocations() {
      return this.props.currentUser.data.managed_locations;
    }

    showOnlyMyLocations() {
      const { forceOnlyManagerLocations } = this.props;
      const myLocations = this.getMyLocations();
      return myLocations && myLocations.length && forceOnlyManagerLocations;
    }

    isSearched() {
      return get(this.props.locations, 'action.payload.search');
    }

    renderFilter = () => {
      if (this.showOnlyMyLocations()) return null;

      const { roles, managed_locations } = this.props.currentUser.data;
      const isManager = roles.indexOf('manager') > -1;

      return (
        <div>
          {(managed_locations || []).length > 0 && isManager && (
            <Checkbox
              label={t('issues.forms.new_issue.only_my_locations')}
              checked={this.props.onlyMyLocations}
              onChange={this.props.onOnlyMyChange}
            />
          )}
        </div>
      );
    };

    getLocationsWithSuggested() {
      const { locations = {}, suggestedLocations = {} } = this.props;

      if (locations.fetching || suggestedLocations.fetching) return null;
      if (this.isSearched() || (suggestedLocations.data || []).length === 0)
        return this.props.modifyTree(locations.data);

      return this.props.modifyTree({
        ...locations.data,
        byId: {
          ...locations.data.byId,
          ...keyBy(suggestedLocations.data, 'id'),
        },
        children: [
          {
            name: t('locations.fields.suggested'),
            id: 'suggested',
            children: suggestedLocations.data.map((location) => ({
              ...location,
              shortName: location.name,
              name: getName(location),
              fullName: getName(location),
            })),
            $forceExpanded: false,
          },
          ...locations.data.children,
        ],
      });
    }
  },
);
