import tp from 'lib/typograph';

interface Location {
  parent_names: string[];
  shortName: string;
  name: string;
  [key: string]: unknown;
}

export const getFullPath = (location?: Location) =>
  !location ? [] : [...(location.parent_names || []), location.shortName || location.name].map(tp);

export const getName = (location: Location) => getFullPath(location).join(' › ');
