import { memo, useRef } from 'react';

import { brandedThemeOptions } from 'lib/ui/AppThemeProvider/theme';
import { bem } from 'lib/bem';
// eslint-disable-next-line import/no-cycle
import { Loader } from 'lib/ui';
import { Icon, IconAsButton } from 'lib/ui/icon';

const { element } = bem('TreeView');

const NodeTitle = ({
  title,
  onTitleClick,
  onToggle,
  onMouseDown,
  isLoading,
  drag,
  drop,
  draggable,
  expanded = false,
  nodeSettings,
  leaf,
}) => {
  const ref = useRef(null);
  if (draggable) {
    drag(drop(ref));
  }

  return (
    <div ref={ref} {...element('node-title')} onMouseDown={onMouseDown}>
      {isLoading && <Loader />}

      {nodeSettings.isDisplayIconFolder ? (
        !nodeSettings.isNodeTypeFolder ? (
          <Icon
            glyph="attribute"
            width={24}
            height={24}
            {...element('expand-node', { isDisplayIcon: true })}
          />
        ) : expanded && !leaf ? (
          <Icon
            glyph="opened_folder"
            {...element('expand-node', { isDisplayIcon: true })}
            width={24}
            height={24}
            sx={{
              fill: brandedThemeOptions.baseColor,
            }}
          />
        ) : (
          <Icon
            glyph="closed_folder"
            {...element('expand-node', { isDisplayIcon: true })}
            width={24}
            height={24}
            sx={{
              fill: brandedThemeOptions.baseColor,
            }}
          />
        )
      ) : (
        <IconAsButton glyph="squareArrow" onClick={onToggle} {...element('expand-node')} />
      )}

      <div {...element('node-name')} onClick={onTitleClick}>
        {title.name ? title.name : title}
        {title.description && <span {...element('node-description')}>{title.description}</span>}
      </div>
    </div>
  );
};

export default memo(NodeTitle);
